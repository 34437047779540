<template>
  <div class="home">
    <div class="container is-fluid">
      <h1 class="title">OCB Dashboard</h1>
        <div class="title-actions">
          <div class="subtitle is-4 has-text-info">Evolutions FDC</div>
          <div class="field has-addons">
            <p class="control">
              <a class="button" :class="{ 'is-primary': perimeter==='projects' }" @click="setPerimeter('projects')">Projets</a>
            </p>

            <p class="control">
              <a class="button" :class="{ 'is-primary': perimeter==='sheets' }" @click="setPerimeter('sheets')">Fiches affaire</a>
            </p>
          </div>
        </div>
        <div class="panel-block evolutions">
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap invisible is-info">Nom budget</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="is-info">Recettes</td>
              </tr>
              <tr>
                <td class="is-info">Débours</td>
              </tr>
              <tr>
                <td class="is-info">Marge</td>
              </tr>
              <tr>
                <td class="is-info">% Marge</td>
              </tr>
            </tbody>
          </table>
          <table class="table is-fullwidth budget" v-for="(budget, index) in evolutions" :key="index">
            <thead>
              <tr>
                <th class="has-text-centered nowrap" :cy-data="'budget-' + index">{{ budget.name ? budget.name : budget.phase.name }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="has-text-centered">{{ budget.totalIncomes | priceEUR }}</td>
              </tr>
              <tr>
                <td class="has-text-centered">{{ budget.totalAmount | priceEUR }}</td>
              </tr>
              <tr>
                <td class="has-text-centered">{{ budget.totalIncomes - budget.totalAmount | priceEUR }}</td>
              </tr>
              <tr>
                <td class="has-text-centered">{{ budget.totalIncomes ? 1 - (budget.totalAmount / budget.totalIncomes) : 0 | percentage(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="">
          <div class="title-actions subtitle is-4 has-text-info">Messages non lus</div>
          <ProjectComments v-for="(project, projectName) in projects" :key="projectName" :project="project" />
        </div>

    </div>
  </div>
</template>

<script>
import ProjectComments from '../components/elements/ProjectComments.vue'

export default {
  name: "Home",
  components: {
    ProjectComments
  },
  data() {
    return {
      projects: null,
      evolutions: [],
      perimeter: 'projects'
    };
  },
  mounted() {
    this.$Api.Comment.fetchUnreadComments(res=>{
      let projects = {}
      res.forEach(comment => {
        if(!projects[comment.sheet.project.name]) {
          projects[comment.sheet.project.name] = { name: comment.sheet.project.name, comments: [] }
        }
        projects[comment.sheet.project.name].comments.push(comment)
      })
      this.projects = projects
    })

    this.fetchEvolutions()
  },
  watch: {
    perimeter() {
      this.fetchEvolutions()
    }
  },
  methods: {
    fetchEvolutions() {
      this.evolutions = []
      Promise.all([
        this.$Api.Budget.fetchAllBudgets(this.perimeter, result => result),
        this.$Api.ReferenceBudget.fetchAllReferenceBudgets(this.perimeter, result => result)
      ]).then(result => {
        let evolutions = {}
        let budgetControls = {}

        result[0].forEach(budget => {
          if(!evolutions[budget.phase.name]) {
            evolutions[budget.phase.name] = {
              name: budget.phase.name,
              totalAmount: 0,
              totalIncomes: 0
            }
          }

          evolutions[budget.phase.name].totalAmount += budget.totalAmount
          evolutions[budget.phase.name].totalIncomes += budget.totalIncomes
        })

        result[1].forEach(budget => {
          if(budget.milestone.name === 'reference_budget') {
              if(!evolutions[budget.name]) {
              evolutions[budget.name] = {
                name: budget.name,
                totalAmount: 0,
                totalIncomes: 0
              }
            }

            evolutions[budget.name].totalAmount += budget.totalAmount
            evolutions[budget.name].totalIncomes += budget.totalIncomes
          }
          if(budget.milestone.name === 'budget_control') {
            if(!budgetControls[budget.name]) {
              budgetControls[budget.name] = {
                name: budget.name,
                totalAmount: 0,
                totalIncomes: 0,
                referenceDate: budget.referenceDate
              }
            }

            budgetControls[budget.name].totalAmount += budget.totalAmount
            budgetControls[budget.name].totalIncomes += budget.totalIncomes
          }
        })

        if(evolutions['Transfert']) this.evolutions.push(evolutions['Transfert'])
        if(evolutions['Objectif']) this.evolutions.push(evolutions['Objectif'])
        if(evolutions['Point 20']) this.evolutions.push(evolutions['Point 20'])
        if(evolutions['Point 50']) this.evolutions.push(evolutions['Point 50'])
        if(evolutions['Point 80']) this.evolutions.push(evolutions['Point 80'])
        if(evolutions['Point 100']) this.evolutions.push(evolutions['Point 100'])
        if(evolutions['APS']) this.evolutions.push(evolutions['APS'])
        if(evolutions['EXE']) this.evolutions.push(evolutions['EXE'])

        const sortedBc = Object.values(budgetControls).sort((a, b) => {
          if(a.referenceDate < b.referenceDate) {
            return -1
          }
          return 1
        })

        this.evolutions = [
          ...this.evolutions,
          ...sortedBc
        ]

      })
    },
    setPerimeter(perimeter) {
      this.perimeter = perimeter
    }
  },
};
</script>

<style scoped lang="scss">
.title-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.table:last-child {
  margin-bottom: 1.5rem;
}
.evolutions {
  overflow-x: auto;
  th {
    padding: 8px 36px;
  }
}
</style>

