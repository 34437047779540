import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchReplies(id, callback) {
    return axios.get('/comments/' + id + '/replies').then((response) => {
      return callback(response.data);
    });
  },
  fetchCommentsSynthesis(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get('/comments/synthesis?' + paginate.toString()).then((response) => response.data);
  },
  fetchUnreadComments(callback) {
    return axios.get('/dashboard/comments').then((response) => {
      return callback(response.data);
    });
  },
};