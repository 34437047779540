<template>
  <div>
    <paginate
      :show-options="!singleSelect"
      :paginate="pagination"
      @change="paginate"
    />

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th class="index-column sortable" @click="sortBy({ sortBy: identifier, sortable: true })">#</th>
          <th class="index-column"></th>
          <th v-for="(column, idx) in internalColumns" :key="idx" @click="sortBy(column)" :class="{ sortable: column.sortable }">
            {{ column.title }}
            <font-awesome-icon
              v-if="pagination.sort.column === column.id || pagination.sort.column === column.sortBy"
              :icon="pagination.sort.direction === 'ASC' ? 'sort-down' : 'sort-up'"
            ></font-awesome-icon>
          </th>
          <th class="action-column"></th>
        </tr>
        <!-- Display bar only if necessary: hasFilters, enableDownload, hasSelection -->
        <tr
          v-if="enableDownload || hasFilters || (!singleSelect && !noSelection)" :class="{ 'has-background-yellow': isFiltered && entity }"
        >
          <td class="has-text-centered">
            <span
              v-if="!singleSelect && enableDownload"
              @click.stop="$refs.download.click()"
            >
              <font-awesome-icon icon="cloud-download-alt" />
            </span>
          </td>
          <td>
            <input
              v-if="!singleSelect && !noSelection"
              type="checkbox"
              v-model="selectAll"
            />
          </td>
          <td v-for="(column, idx) in internalColumns" :key="idx">
            <input
              v-if="column.hasFilter"
              class="filter-text"
              :type="column.inputType"
              :name="column.id"
              v-model.trim="internalColumns[idx].filter"
              @input="updateFilter"
            />
          </td>
          <td class="action-column"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td :colspan="internalColumns.length + 3" class="loader-container">
            <i class="loader"></i>
          </td>
        </tr>
        <tr
          v-for="(item, index) in items"
          :key="index + '_' + pagination.page"
          :class="{
            selected:
              selected &&
              ((singleSelect && selected === item) ||
                (!singleSelect &&
                  item &&
                  selected &&
                  selected.findIndex(e => e.id === item.id) !== -1)),
            selectable: selector,
          }"
          @click="selector && !singleSelect && toggleSelect(item) || singleSelect && $emit('input', item)"
        >
          <td v-if="entity">
            <div class="line-start">
              <span>#{{ (pagination.page - 1) * pagination.size + index + 1 }}</span>
              <router-link v-if="getRights(entity).read" :to="'/' + entity + '/' + item.id" class="button goTo-button is-info">
                <font-awesome-icon icon="search" />
              </router-link>
            </div>
          </td>
          <td v-else>#{{ (pagination.page - 1) * pagination.size + index + 1 }}</td>
          <td>
            <input
              v-if="!singleSelect && !noSelection"
              type="checkbox"
              @click.stop
              @input="toggleSelect(item)"
              :checked="selected && selected.findIndex(e => e.id === item.id) !== -1"
            />
            <font-awesome-icon v-if="selected && selected === item" icon="check" />
          </td>
          <td v-for="(column, idx) in internalColumns" :key="idx" :cy-data="column.id">
            <slot :name="column.id" :item="item">{{
              displayItem(item, column.id)
            }}</slot>
          </td>
          <td class="action-column">
            <slot name="actions" :item="item" :index="index"></slot>
          </td>
        </tr>
        <tr v-if="!loading && items.length == 0">
          <td :colspan="internalColumns.length + 3" class="no-data">
            <div>{{ emptyTableMessage }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <form
      v-if="enableDownload"
      :style="{ display: 'none' }"
      target="_blank"
      :action="downloadPath"
      method="POST"
    >
      <button type="submit" ref="download"></button>
      <input type="hidden" name="token" :value="authToken" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import PaginationBuilder from "@/lib/data/Paginate";
import Paginate from "./Paginate";

export default {
  name: "datatable",
  components: {
    Paginate,
  },
  props: {
    fetch: Function,
    entity: String,
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selector: {
      type: Boolean,
      default: false,
    },
    noSelection: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array],
      default: () => null,
    },
    columns: {
      type: Object,
      default: () => null,
    },
    enableDownload: {
      type: Boolean,
      default: false,
    },
    downloadEntity: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 50,
    },
    filterColumns: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: "id",
    },
    customEmptyTableMessage: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "ASC",
    },
  },
  data() {
    return {
      loading: false,
      internalColumns: [],
      hasFilters: false,
      items: [],
      pagination: {
        page: 1,
        total: 0,
        size: this.size,
        sort: {
          column: this.identifier,
          direction: this.order,
        },
      },
      selectAll: false,
      selected: [],
      API: axios.defaults.baseURL,
      emptyTableMessage: this.customEmptyTableMessage,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selectAll() {
      this.selected = [];
      if (this.selectAll === true) {
        this.items.forEach((item) => {
          this.selected.push(item);
        });
      }
      this.$emit("input", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      getRights: 'Auth/getRights',
      authToken: 'Auth/getToken',
      getFilters: 'Filters/getFilters',
    }),
    selectedToUrl() {
      return this.selected ? this.selected.map(object => object.id).toString() : "";
    },
    isFiltered() {
      let empty = true;
      this.internalColumns.forEach((column) => {
        if (column.filter !== '') {
          empty = false;
        }
      });
      return !empty;
    },
    downloadPath() {
      if(this.downloadEntity) {
        return `${this.API}/${this.downloadEntity}/csv?${this.getParams()}&selected=${this.selectedToUrl}`
      }
      if(this.entity) {
        return `${this.API}/${this.entity}s/csv?${this.getParams()}&selected=${this.selectedToUrl}`
      }

      return ''
    }
  },
  methods: {
    init() {
      this.selected = this.value;

      /* Columns are automatically determined by the children. */
      if (!this.columns) {
        Object.keys(this.$scopedSlots).forEach((key) => {
          const slot = this.$scopedSlots[key];

          const slotBlockData = slot({ item: "test" })[0].data;
          if (!slotBlockData.attrs) {
            return;
          }
          const t = slotBlockData.attrs.title;
          const nofilter = slotBlockData.attrs.nofilter != null;

          if (!nofilter) {
            this.hasFilters = true;
          }

          this.internalColumns.push({
            id: key,
            fn: slot,
            title: t,
            hasFilter: !nofilter,
            filter: "",
            inputType: slotBlockData.attrs.type
              ? slotBlockData.attrs.type
              : "text",
            sortable: slotBlockData.attrs.sortable || false,
            sortBy: slotBlockData.attrs.sortBy || key,
          });
        });
      } else {
        Object.keys(this.columns).forEach((id) => {
          let title = "";
          let filter = "";
          let hasFilter = this.filterColumns;
          if (typeof this.columns[id] === "string") {
            title = this.columns[id];
          } else {
            const column = this.columns[id];
            title = column.title;
            hasFilter = column.filter != null;
            filter = column.filter;
          }

          if (hasFilter) {
            this.hasFilters = true;
          }

          this.internalColumns.push({
            id,
            title,
            filter,
            hasFilter,
            sortable: this.columns[id].sortable || false,
            sortBy: this.columns[id].sortBy || this.key,
          });
        });
      }
      const entityFilters = this.getFilters(this.entity);
      if (entityFilters) {
        this.internalColumns = entityFilters.filters;
        this.pagination.sort = entityFilters.sort;
      }
      this.refresh();
    },
    refresh() {
      const p = this.preparePaginationObject();

      this.items = [];
      this.loading = true;
      this.fetch(p)
        .then(this.callback)
        .catch(this.fetchError)
        .finally(() => {
          this.loading = false;
        });
    },
    callback(data) {
      this.pagination.total = data.totalElements;
      this.items = data.content;
      this.emptyTableMessage = "Aucune donnée";
    },
    fetchError() {
      this.emptyTableMessage = "La récupération des données n'a pas fonctionné";
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: this.internalColumns.reduce((filters, column) => {
          const allFilters = filters;
          allFilters[column.id] = column.filter;
          return filters;
        }, {}),
      };
    },
    getParams() {
      const p = new PaginationBuilder(this.preparePaginationObject());
      return p.toString();
    },
    paginate(p) {
      this.pagination = p;
      this.refresh();
    },
    displayItem(item, column) {
      const keys = column.split(".");
      let result = item;
      for (let i = 0; i < keys.length; i += 1) {
        const k = keys[i];
        if (!result[k]) {
          return "";
        }
        result = result[k];
      }
      return result;
    },
    toggleSelect(item) {
      if (!this.selected) {
        this.selected = [];
      }
      const idx = this.selected && this.selected.findIndex(e => e.id === item.id);
      if (idx === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(idx, 1);
      }
      this.$emit("input", this.selected);
    },
    updateFilter: debounce(function refresh() {
      if (this.entity) {
        this.$store.dispatch('Filters/setFilters', { name: this.entity, filters: this.internalColumns, sort: this.pagination.sort });
      }
      this.refresh();
    }, 500),
    download() {
      this.$emit("download", null);
    },
    sortBy(column) {
      const attribute = column.sortBy || column.id;

      if (!column.sortable) return;

      if (this.pagination.sort.column === attribute) {
        this.pagination.sort.direction = this.pagination.sort.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.pagination.sort.direction = 'ASC';
        this.pagination.sort.column = attribute;
      }

      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #019fc4;

.index-column {
  width: 20px;
}
.table th {
  text-align: center !important;
}

tbody {
  font-size: 14px;
}

.action-column {
  width: 50px;
  .button {
    height: 20px;
  }
}

.loader-container {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}

.no-data {
  text-align: center;
  background-color: #fff;
  color: #777;
  padding: 40px 0;
}

.selectable {
  cursor: pointer;
}

.selected {
  background-color: #cdffe7;
}

.fa-cloud-download-alt {
  color: $axione-blue;
  font-size: 24px;
  cursor: pointer;
}
.filter-text {
  width: 100%;
}

input[type="text"] {
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 22px;
  padding: 2px 5px;
  margin: 0;
}

input[type="date"] {
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 22px;
  padding: 2px 5px;
  margin: 0;
}

input[type="checkbox"] {
  background-color: white;
  width: 18px;
  height: 18px;
}

.goTo-button {
  height: inherit;
  padding: 0 .6em;
  font-size: 14px;
  margin-left: 3px;
}
.line-start {
  display: flex;
}

.sortable {
  cursor: pointer;
  color: rgb(50, 115, 220);
  i {
    margin-left: 5px;
  }
}
</style>
